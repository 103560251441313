import React from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import GenericCtaButton from '../GenericCtaButton';
import util from '../util';

function validateFields(list) {

  for (let i = 0; i < list.length; i++) {
    list[i].fields.CardTitle.value = util.setVarLength(list[i].fields.CardTitle.value, 75);
    list[i].fields.CardDescription.value = util.setVarLength(list[i].fields.CardDescription.value, 300);
    if(list[i].fields.CTALink.value.text !== undefined) {
      list[i].fields.CTALink.value.text = util.setVarLength(list[i].fields.CTALink.value.text, 15);
    }
  }

  return list;

}

const TeaserWithCards = (props) => {

  if (props.fields) {

    let { TeaserCards, BackgroundStyling, TeaserTitleType, TeaserTitle, TeaserDescription } = props.fields;
    let sectionClass = (BackgroundStyling) ? BackgroundStyling.fields.ClassName.value : 'section-bg-blue';
    
    let teaserList = validateFields(TeaserCards);

    return (
      <div className={`container-fluid sections ${sectionClass}`}>
        <div className="container">
          <div className="row text-center text-white">
            <div className="section-title col-md-12">
              <Text tag={`${TeaserTitleType.value}`} field={TeaserTitle} />
            </div>
            <div className="col-md-12 content editor">
              <div className="editor-content">
                <RichText field={TeaserDescription} />
              </div>
            </div>
          </div>
          <div className="row">
            {teaserList &&
              teaserList.map((listItem, index) => {
                if (listItem.fields && (
                (listItem.fields.CardTitle.value && listItem.fields.CardTitle.value !== null && listItem.fields.CardTitle.value !== '') || 
                (listItem.fields.CardDescription.value && listItem.fields.CardDescription.value !== null && listItem.fields.CardDescription.value !== '') 
                ) ) {
                  let { ImgIcon, CardTitleType, CardTitle, CardDescription, CTALink, CTAType, } = listItem.fields;
                  return (
                    <React.Fragment key={index}>
                      <div className="col-sm-12 col-md-4 col-lg-3 text-center text-white mx-auto">
                        <div className="teaser-content">
                          <div className="icon">
                            <Image field={ImgIcon} className="tile-image" />
                          </div>
                          <div className="title">
                            <Text tag={`${CardTitleType.value}`} field={CardTitle} />
                          </div>
                          <div className="content">
                            <Text tag="p" field={CardDescription} />
                          </div>
                          {((CTALink.value.text !== undefined) && 
                          (CTALink.value.text !== "")) && (
                            <div className="CTA-container">
                              <GenericCtaButton 
                                props={CTALink} 
                                CTAType={CTAType?CTAType.fields.ClassName.value:"primary"} 
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              })
            }
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
export default TeaserWithCards;
