import React from "react";
import { Text, RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import GenericCtaButton from "../GenericCtaButton";
import util from "../util";

const PromotionalCard = (props) => {
  if (props.data.fields) {
    let {
      ImageText,
      Title,
      ShortDescription,
      CallToAction,
      CallToActionCTAType,
    } = props.data.fields;
    let imageIcon = props.data.fields.Image;

    ImageText.value = util.setVarLength(ImageText.value, 35);
    Title.value = util.setVarLength(Title.value, 35);
    ShortDescription.value = util.setVarLength(ShortDescription.value, 150);
    CallToAction.value.text = util.setVarLength(CallToAction.value.text, 25);

    return (
      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
        <div className="product-tile promotional-tile bg-white text-center">
          {imageIcon.value.src !== undefined && imageIcon.value.src !== "" && (
            <div className="image-container">
              <Image field={imageIcon} />
              <Text tag="h4" field={ImageText} />
            </div>
          )}
          <div className="tile-content">
            <div className="title">
              <RichText tag="h3" field={Title} />
            </div>
            <div className="content">
              <RichText field={ShortDescription} />
            </div>
            <div className="CTA-container">
              <GenericCtaButton
                props={CallToAction}
                CTAType={
                  CallToActionCTAType
                    ? CallToActionCTAType.fields.ClassName.value
                    : "tertiary-w-icon"
                }
              />
            </div>
            
          </div>
         
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default PromotionalCard;
