module.exports = {
  app: {
    // url: "http://localhost:3000",
    // "url": "http://ethos.dev.local/",
    url: "http://ethos.dev.local",
  },

  aws: {
    endpoint:
      "https://ethosdomain.auth.us-east-1.amazoncognito.com/authorize?idp_identifier=infinitysp&response_type=token&client_id=5d174ukd57pbkd2an8hrvgj9q3&redirect_uri=",
    clientId: "5d174ukd57pbkd2an8hrvgj9q3",
    region: "us-east-1",
  },

  session: {
    storageKey: "AWS_TOKEN_AUTH_KEY",
  },

  API: {
    logoutAPI: "/api/Sitecore/SessionData/SignOut",
    userDataAPI: "/api/Sitecore/SessionData/GetUserprofiledata",
  },

  path: {
    loginURL: "/marketplace/login",
    logoutURL: "/marketplace/",
    profileURL: "/profile/user-profile-view",
    ceclURL: "/cecl-view",
  },

  apolloClient: {
    url: 
      "/sitecore/api/graph/items/master/?sc_apikey={92C198BC-9006-4E78-A4A3-6E97E1800A9D}",
  },

  wistia: {
    endpoint: "https://fast.wistia.com/embed/medias/",
    script: "https://fast.wistia.com/assets/external/E-v1.js",
  },
};
